.container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #efefef;
}

.header,.left,.right {
  display: flex;
  align-items: center;
}

.header {
  justify-content: space-between;
  min-height: 75px;
  width: 100%;
  position: relative;
  padding: 15px 20px;
  box-sizing: border-box;
}

.left > *:not(:first-child),.right > *:not(:first-child) {
  margin-left: 15px;
}

.title {
  font-size: 21px;
  font-weight: 500;
  height: 100%;
  display: flex;
  align-items: center;
  line-height: 2.5;
}

.content {
  position: relative;
  width: 100%;
  max-width: 1024px;
  flex-grow: 1;
  padding-bottom: 20px;
  box-sizing: border-box;
  background: #fff;
  display: flex;
  flex-direction: column;
  border: 1px solid #dbdbdb;
  box-shadow: 0 1px 5px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}


.menuWrapper {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background: #fff;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 999;
}

.back {
  height: 20px;
  vertical-align: middle;
}

.dropdown > * {
  margin: 5px 14px 5px 14px;
}
.dropdown > *:last-child {
  margin-bottom: 14px;
}
.dropdown > *:first-child {
  margin-top: 14px;
}

.mobileSubmitButton {
  position: fixed;
  bottom: 0;
  width: 100vw;
  left: 0;
  align-items: flex-end;
  border-radius: 0;
  z-index: 99;
  height: 80px;
  padding-right: 20px;
}

.mobileSubmitButton > * {
  font-size: 16px !important;
}

@media (min-width: 1024px){
  .dropdown > *:not(:last-child) {
    margin-bottom: 0;
  }
  .header {
    height: 75px;
    padding: 0 50px;
    justify-content: center;
  }
  .left,.right {
    position: absolute;
  }
  .left {
    left: 50px;
  }

  .right {
    right: 50px;
  }
  .content {
    border-radius: 4px;
    margin-top: 40px;
    border: none;
  }
  .container .title {
    align-items: flex-end;
  }
  .mobileSubmitButton,.right > *:first-child,.right > *:first-child > * {
    background: #24D600 !important;
    color: #000 !important;
  }
}

@media (min-width: 1441px){
  .content {
    max-width: 1200px;
  }
}
