.header {
  color: #fff;
  background: #8b8b8b;
  padding: 10px;
  font-size: 28px;
  padding: 10px 5px 10px 25px;
}

@media (min-width: 1024px){
  .header {
    padding: 10px 45px;
  }
}
