.container {
  background: #000;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 45px;
  height: 65px;
  font-size: 13px;
}

.links {
  display: none;
}

.links > * {
  text-transform: uppercase;
  color: #fff;
  text-decoration: none;
  margin-left: 55px;
}

@media (min-width: 1024px){
  .links {
    display: flex;
    align-items: center;
  }
}
