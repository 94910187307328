

.container {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 100px;
  height: 100%;
  justify-content: space-between;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;
}

.button > svg {
  width: 28px;
  height: 28px;
  fill: #000;
}

.disabled {
  opacity: 0;
  cursor: default;
}


.outer {
  flex-grow: 1;
  overflow-x: hidden;
  position: relative;
  height: 100%;
}
.inner {
  position: absolute;
  display: flex;
  align-items: center;
  height: 100%;
  transition: left 400ms ease-in-out;
}

@media (min-width: 1024px){
  .button > svg {
    width: 46px;
    height: 46px;
  }
}
