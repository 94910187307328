.container {
  position: relative;
  height: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
}

.image {
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  width: auto;
}
.icon {
  width: 64px;
  height: 64px;
}

.menuWrapper {
  position: absolute;
  top: 5px;
  right: 5px;
}

.menu {
  width: 28px;
  height: 28px;
  background: #000;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.text,.fileName {
  color: #fff;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  padding: 0 5px;
  box-sizing: border-box;
  text-align: center;
}
.banner {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #000;
  align-items: center;
  justify-content: center;
  display: flex;
  text-align: center;
  padding: 3px 10px;
  border-radius: 0 0 4px 4px;
  box-sizing: border-box;
}

.banner img {
  display: block;
  width: 24px;
}

.fileName {
  font-size: 14px;
  margin-bottom: 8px;
}


@media (min-width: 1024px){
  .banner,.menu {
    display: none;
  }
  .container:hover .banner,.container:hover .menu {
    display: flex;
  }
}
