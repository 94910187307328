.container {
  padding: 15px 7px;
  border-top: 2px solid #dbdbdb;
  display: block;
  color: #000;
  text-decoration: none;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

}

.title {
  font-size: 18px;
  font-weight: 500;
  color: #000;
  text-decoration: none;
}

.container.collapsible .header {
  cursor: pointer;
}

.container.collapsible .content {
  overflow: hidden;
  transition: max-height 0.2s linear;
}

.padding {
  padding: 35px 0;
}



.toggle {
  width: 35px;
  transition: transform 0.2s ease-in-out;
}

.container.open .toggle {
  transform: rotateX(180deg);
}
