.titleWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
}

@media (min-width: 1024px) {
  .titleWrapper {
    flex-direction: row;
  }
}
