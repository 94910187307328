.container {
  position: relative;
}

.label {
  display: flex;
  align-items: center;
}

.buttonWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.deleteButton {
  margin-top: 5px;
}

@media (min-width:1024px) {
  .buttonWrapper {
    flex-direction: row;
  }
  .deleteButton {
    margin-top: 0;
    margin-left: 5px;
  }
}
