.container {
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
  opacity: 0;
  transition: opacity .15s linear;
  margin: 0 !important;
}

.content {
  background: #fff;
  position: relative;
  max-width: 560px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 260px;
  border-radius: 4px;
  max-height: 100vh;
  overflow-y: auto;
  padding: 54px;
  transition: transform .3s ease-out;
  transform: translateY(-5em);
  box-sizing: border-box;
}

.content.show {
  transform: none;
}

.container.show {
  opacity: 1;
}

.text {
  margin-bottom: 27px;
  font-size: 21px;
  display: flex;
  align-items: center;
}

.buttonRow {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.buttonRow > *:first-child {
  margin-right: 20px;
}

.closeIcon {
  position: absolute;
  top: 10px;
  right: 10px;
}

@media (max-width: 868px){
  .container {
    overflow-y: scroll;
    align-items: flex-start;
  }
  .content {
    min-height: 100vh;
    min-width: 100%;
    border-radius: 0;
    width: 100%;
    padding: 3em 2em;
  }
  .container::-webkit-scrollbar {
    display: none;
  }
}


@media (max-width: 868px) and (orientation: landscape){
  .container {
    justify-content: flex-start;
  }
}
