.container {
  width: 100%;
  position: relative;
  display: block;
  font-size: 10px;
  margin-bottom: 10px;
}

.container > *:first-child {
  display: flex;
  align-items: center;
}

.value {
  font-size: 16px;
  width: 100%;
}

.value > a {
  color: #000;
}



@media (min-width: 1024px) {
  .container {
    display: flex;
    margin-bottom: 24px;
  }
  .secondary {
    display: block;
  }
  .value {
    padding: 0 14px;
  }

  .secondary .value {
    margin-top: 8px;
    padding: 0;
  }
}
