
.container {
    background: #fff;
    position: relative;
    border-bottom: 1px solid #e5e5e5;
    padding: 8px 10px;
}

.container.active {
  background-color: #939393;
}

.container.active .from,.container.active .title,.container.active .date {
  color: #fff;
}

.container:hover {
  background-color: #F2F6F9;
  cursor: pointer;
}

.container.active:hover .date {
  color: #888;
}

.container.active .from,.container.active .title {
  color: #333;
}

.from, .title {
  color: #333;
  font-size: 12px;
}

.from {
  display: inline-block;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  padding-right: 10px;
  flex-grow: 1;
  box-sizing: border-box;
}

.date {
  font-size: 10px;
  color: #888;
  font-style: italic;
  padding-right: 5px;
  flex-shrink: 0;
}
