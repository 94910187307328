body {
  margin: 0;
  position: relative;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
}

body > #root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: #efefef;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.selectable,.highlight {
  transition: all .2s ease-in-out;
  cursor: pointer;
}

.highlight:hover {
  background: #f9f9f9;
}

.selectable:active,.selectable:focus,.selectable:hover,.darken {
  filter: brightness(90%);
}
.hidden {
  position: absolute !important;
  display: none !important;
}


button,input,select,textarea {
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}


button {
  background: none;
  outline: none;
  border: none;
}


input,select,textarea {
  background: none;
  outline: none;
  width: 100%;
  font-size: 16px;
  background: #fff;
  box-sizing: border-box;
  border: 1px solid #24D600;
  border-radius: 4px;
}
input,select {
  height: 42px;
  padding: 0 14px;
}

textarea {
  padding: 14px;
}


select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url(@assets/images/dropdown.svg);
  background-repeat: no-repeat;
  background-position-x: calc(100% - 5px);
  background-position-y: 50%;
}

select::-ms-expand {
  display: none;
}

select::-ms-expand,.react-select-container .react-select__control::-ms-expand {
  display: none;
}


input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    position: absolute;
    right: 8px;
}
