.container {
  height: 120px;
}

.listContainer {
  padding-bottom: 50px;
  position: relative;
  width: 100%;
}


@media (min-width: 1024px) {
  .container {
    height: 150px;
  }
}
