.container {
  background: #000;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  padding: 0 5px 0 25px;
}

.content {
  height: 80%;
  justify-content: space-between;
  flex-grow: 1;
}

.content,.right,.right,.logo {
  display: inline-flex;
  align-items: center;
}
.logo {
  height: 100%;
}
.logo > img {
  width: 90px;
}

.logo2 {
  width: 60px;
}
.links {
  height: 100%;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80vw;
  left: -80vw;
  box-sizing: border-box;
  background: #000;
  top: 0;
  bottom: 0;
  border-bottom: 3px solid #24D600;
  transition: all 0.2s;
  z-index: 9999;
}


.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  z-index: 999;
}
.overlay.open {
  background: rgba(0,0,0,0.4);
  display: block;
}

.links.open {
  left: 0;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.links.open::-webkit-scrollbar {
  display: none;
}

.right {
  height: 100%;
  display: flex;
  align-items: center;
}

@media (min-width: 1024px){
  .container {
    height: 95px;
    padding: 0 45px;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .logo > img {
    width: 130px;
  }
  .content {
    height: 76px;
  }
  .links {
    align-items: center;
    flex-direction: row;
    margin-left: 45px;
    position: relative;
    width: auto;
    background: transparent;
    left: 0;
    border-bottom: none;
  }
  .links > *:not(:first-child) {
    margin-left: 40px;
  }
}
@media (min-width: 1441px){
  .logo > img {
    width: 140px;
  }
  .links {
    margin-left: 80px;
  }
  .links > *:not(:first-child) {
    margin-left: 90px;
  }
}
