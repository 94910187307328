
/* Gallery */
.galleryContainer {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: #000;
  z-index: 99998;
  display: flex;
  flex-direction: column;
}
.galleryHeader {
  height: 40px;
  display: flex;
  padding: 0 5px;
  align-items: center;
  justify-content: flex-end;
  background: #191919;
}

.galleryContent {
  display: flex;
  flex-grow: 1;
  height: calc(100vh - 40px);
  position: relative;
}

.gallery {
  flex-grow: 1;
  display: flex;
  height: 100%;
}

.imageWrapper {
  flex-grow: 1;
  display: flex;
  padding: 16px;
  box-sizing: border-box;
  position: relative;
  justify-content: center;
  overflow: hidden;
}

.imageTransformWrapper {
  position: relative;
  display: flex;
  flex: 1 1;
  justify-content: center;
}
.button {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  cursor: pointer;
}

.arrow {
  fill: #fff;
  width: 24px;
  height: 24px;
}

.galleryImage {
  justify-content: center;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  flex: 1 1;
}

.galleryInfo {
  background: #191919;
  width: 120px;
  padding: 8px;
  box-sizing: border-box;
}

.galleryInfoContainer {
  font-size: 13px;
}

.galleryInfoContainer * {
  color: #fff;
}

.galleryInfoLabel {
  color: #b3b3b3;
  padding-bottom: 6px;
}
.galleryHeaderButtonContainer {
  height: 100%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.galleryHeaderButtonContainer:hover {
  background: #333;
}

.galleryInfoValue {
  color: #fff;
  overflow: hidden;
  padding-bottom: 10px;
}

.galleryInfoHeading {
  position: relative;
  color: #fff;
  padding-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
}

.imageBanner {
  position: absolute;
  bottom: 20px;
  background: #000;
  color: #fff;
  display: none;
  align-items: center;
  justify-content: center;
}

.imageWrapper:hover .imageBanner {
  display: flex;
}

.button.disabled {
  cursor: default;
}
.button.disabled > .arrow {
  fill: #666;
}

.fileContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.fileIcon {
  width: 50%;
  max-width: 200px;
  height: auto;
}

.fileName {
  color: #fff;
  font-size: 28px;
}

.fileViewerWrapper {
  width: 100%;
  position: relative;
  text-align: center;
}

.fileViewerWrapper > * {
  text-align: center;
}

.actionButton {
  display: flex;
  align-items: center;
  width: 130px;
  border-radius: 4px;
  height: 40px;
  padding: 0 8px;
  background: #101010;
  margin-top: 20px;
  text-decoration: none;
  color: #fff;
  font-size: 15px;
  box-sizing: border-box;
}

.actionButton:last-child {
  margin-top: 10px;
}
.actionButtonText {
  color: #fff;
  text-decoration: none;
}

.actionButtonIcon {
  margin-right: 5px;
  width: 22px;
}
@media (min-width: 1024px) {
  .arrow {
    width: 48px;
    height: 48px;
  }
  .button {
    padding: 0 20px;
  }
  .galleryInfo {
    width: 300px;
    padding: 16px;
  }
  .galleryHeader {
    height: 50px;
    padding: 0 15px;
  }
}
