.fileName {
  margin-left: 10px;
  color: #000;
  text-decoration: none;
}

.fileName:hover {
  text-decoration: underline;
}

.left,.right {
  display: flex;
  align-items: center;
}

.left {
  cursor: pointer;
}

.right > * {
  margin-left: 20px;
}

.right img {
  width: 26px;
  display: block;
}

.file {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.image {
  width: 35px;
  height: auto;
}
