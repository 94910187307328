.search {
  color: #ddd;
  display: inline-flex;
  align-items: center;
  margin: 28px 0 16px 0;
}

.searchIcon {
  width: 20px;
  margin: 3px 5px 0 0;
}

.inputWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 20px 0 50px 0;
}

.input {
  position: relative;
  border: none;
  max-width: 80%;
  width: 100%;
  font-size: 22px;
}

.modal {
  background: #8b8b8b;
  padding: 5px;
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  border-radius: 0;
}
.modal > svg {
  fill: #fff;
}

.searchContent {
  overflow-y: scroll;
  width: 100%;
  padding: 0 50px;
}

.section {
  text-align: center;
  color: #fff;
  padding: 30px 0;
}

.section:not(:last-child) {
  border-bottom: 1px solid #fff;
}

.sectionLabel {
  font-size: 22px;
  margin-bottom: 10px;
  text-transform: capitalize;
  font-weight: bold;
  text-decoration: underline;
  text-decoration-color: #666;
}

.sectionList {
  font-size: 16px;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.placeholderText {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 24px;
}

.item {
  margin: 2px 0;
  padding: 6px 30px;
  box-sizing: border-box;
  font-size: 16px;
  color: #fff;
  background: #8b8b8b;
  text-decoration: none;
}

.searchContentContainer {
  position: relative;
  height: 100%;
}

.viewLink {
  margin-top: 20px;
  color: #444;
  display: block;
  text-decoration: underline;
  cursor: pointer;
}

.viewLink:hover {
  color: #000;
}

.backWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  cursor: pointer;
}
.backText {
  font-size: 18px;
  text-transform: capitalize;
  color: #fff;
  text-decoration: underline;
  text-decoration-color: #666;
}

.back {
  width: 15px;
  height: 15px;
}

.pagination {
  bottom: 35px;
  position: fixed;
}

.paginatedList {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media (min-width: 1024px) {
  .searchContent {
    flex-direction: row;
    max-width: 45%;
    overflow-y: auto;
    display: flex;
    justify-content: space-around;
  }
  .search {
    height: 100%;
    margin-right: 40px;
    padding-right: 52px;
    margin: 0;
    border-right: 1px solid #fff;
  }
  .input {
    max-width: 36%;
  }
  .section {
    padding-top: 0;
  }
  .section:not(:last-child) {
    border-bottom: none;
  }
  .backText {
    font-size: 24px;
  }

}
@media (min-width: 1441px){
  .search {
    margin-right: 45px;
    padding-right: 60px;
  }
}
