.container {
  margin-top: 15px;
  text-align: right;
}

.container > * {
  margin-left: 30px;
}
.link {
  color: #000;
}

.link:last-child {
  margin-right: 50px;
}
