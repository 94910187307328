.container {
  border: 1px dashed #24D600;
  padding: 35px 20px 0 20px;
  margin-bottom: 10px;
  position: relative;
  max-width: 500px;
}

.close {
  position: absolute;
  top: 5px;
  right: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.hidden {
  display: none;
}

.title {
  position: absolute;
  font-size: 14px;
  font-weight: bold;
  top: 5px;
  left: 5px;
  display: flex;
  align-items: center;
}

.dragIcon {
  margin-right: 2px;
}
