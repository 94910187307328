.dropdown {
  transition: max-height .1s ease-out;
  overflow: hidden;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
  top: 105%;
  background: #fff;
  box-sizing: border-box;
  border-radius: 4px;
  z-index: 999;
  box-shadow: 0 1px 2px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.headerLink {
  width: 100%;
  position: relative;
  border-radius: 0;
  box-shadow: none;
  top: 0;
}

.container {
  position: relative;
}


@media (min-width: 1024px){
  .headerLink {
    position: absolute;
    left: 0;
    right: auto;
    width: fit-content;
    top: 95px;
    box-shadow: 0 1px 2px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  }
}
