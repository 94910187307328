.container {
  border-radius: 4px;
  height: 200px;
  width: 200px;
  position: relative;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.small {
  height: 65px;
  width: 65px;
}


.image {
  max-width: 100%;
  max-height: 100%;
}
.text {
  color: #fff;
}
