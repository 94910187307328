.container {
  padding: 10px;
  border-bottom: 1px solid #D8D8D8;
  white-space: normal;
  justify-content: space-between;
  box-sizing: border-box;
}

.title,.container {
  display: flex;
  align-items: center;
}

.date {
  color: #888;
  font-size: 11px;
  font-style: italic;
}
