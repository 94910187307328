.flex {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.flex > * {
  margin-bottom: 0;
}

.flex > *:last-child {
  margin-left: 10px;
}

.label {
  margin-bottom: 10px;
}
