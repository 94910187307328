.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid #ddd;
  text-decoration: none;
  color: #000;
  font-size: 16px;
  padding: 0 16px;
  text-align: center;
}

.container:hover {
  border: 1px solid #bbb;

}

.image {
  height: 65px;
}
