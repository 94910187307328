.container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}


.title {
  font-size: 21px;
  font-weight: 500;
  padding: 12px 12px 0 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
}
.content {
  padding: 25px;
  box-sizing: border-box;
}

@media (min-width: 1024px){
  .title {
    padding: 25px 25px 0 25px;
  }
  .flex {
    flex-direction: row;
  }
  .content {
    max-width: none;
  }
  .flex > * {
    box-sizing: border-box;
  }
  .flex > *:first-child {
    padding-right: 25px;
  }
  .flex > *:last-child {
    padding-right: 25px;
  }
  .flex > * {
    width: 50%;
  }
}
