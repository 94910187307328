.container {
  display: flex;
  flex-grow: 1;
}

.emailList {
  width: 100%;
  flex-shrink: 0;
}



.noPadding {
  padding: 0 !important;
}


@media (min-width: 1024px) {
  .emailList {
    width: 400px;
    border-right: 1px solid #D8D8D8;
  }
}
