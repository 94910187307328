.container {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
}

.input {
  border: none;
  outline: none;
  background: none;
  border-bottom: 1px solid #DADCE0;
  width: 100%;
  font-size: 1.6em;
  margin-top: 0.2em;
  padding: 0.4em 0.2em;
}

.icon {
  position: absolute;
  right: .8em;
  z-index: 98;
}

.disabled {
  background-color: #ddd;
}
