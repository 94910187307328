.container {
  flex-grow: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  display: flex;
  flex-direction: column;
  background: #fff;
}

.bodyWrapper {
  flex-grow: 1;
  position: relative;
  overflow-y: auto;
}

.body {
  display: flex;
  flex-direction: column;
  padding: 10px;
  box-sizing: border-box;
}

@media (min-width: 1024px) {
  .container {
    position: relative;
    z-index: auto;
    max-height: 600px;
  }
}
