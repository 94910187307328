.container {
  display: flex;
  align-items: center;
  font-size: 13px;
  margin: 8px 0;
}

.label {
  width: 100px;
}
