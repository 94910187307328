
.link {
  font-size: 18px;
  text-transform: uppercase;
  white-space: nowrap;
  margin: 20px 0;
  border-bottom: 3px solid transparent;
  color: transparent;
  justify-content: center;
  text-decoration: none;
  font-weight: 500;
  width: auto;
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
  text-transform: uppercase;
  position: relative;
  transition: all .2s linear;
}

.link::after {
  content: attr(title);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: inherit;
}

.link.active {
  border-bottom-color: #24D600;
}


.dropdownLabel {
  color: #fff;
  padding: 7px 18px;
  font-size: 16px;
  text-align: left;
  text-transform: capitalize;
  background: #8b8b8b;
}

.dropdownContent {
  display: flex;
  flex-direction: column;
}

.dropdownWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
}

.menuLink {
  padding: 5px 18px 5px 18px;
  color: #000;
  background: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  border-bottom: 1px solid #f2f2f2;
}


.viewAllLink {
  padding: 0 10px;
  color: #000;
  background: #fff;
  text-decoration: underline;
  height: 30px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdownIcon {
  margin-left: 25px;
}

@media (min-width: 1024px){
  .dropdownContent {
    white-space: nowrap;
  }
  .dropdownWrapper {
    height: 100%;
  }
  .link {
    font-size: 11px;
    height: 100%;
    margin: 0;
  }
}
@media (min-width: 1351px){
  .link {
    font-size: 12px;
  }
}

@media (min-width: 1441px){
  .link {
    font-size: 14px;
  }
  .search {
    margin-right: 45px;
    padding-right: 60px;
  }
}
