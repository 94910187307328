
.error {
  position: absolute;
  top: 110%;
  width: 100%;
  left: 0;
  font-weight: bold;
  font-size: 11px;
  animation: shake 0.5s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  color: #e23e4d;
}

.error::first-letter {
  text-transform: uppercase;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(1px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-2px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(2px, 0, 0);
  }
}
