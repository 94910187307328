.react-select-container {
  width: 100%;
  min-width: 300px;
  text-align: left;
}

.react-select__control {
  background: #fff !important;
  font-size: 16px !important;
  color: #000 !important;
  min-height: 42px !important;
  height: 42px !important;
  padding: 0 14px !important;
  border-radius: 4px !important;
  border: 1px solid #24D600 !important;
  box-shadow: none !important;
}

.react-select__value-container {
  height: 100%;
  padding: 0 !important;
}

.react-select__multi-value,.react-select__single-value {
  margin: 0 !important;
}

.react-select__multi-value:not(:first-child),.react-select__single-value:not(:first-child) {
  margin-left: 4px !important;
}

.react-select__menu {
  font-size: 16px !important;
}
.react-select__single-value {
  color: #000 !important;
}
.react-select__placeholder,.react-select__single-value {
  position: relative !important;
  top: 0 !important;
  transform: none !important;
}

.react-select__placeholder {
  color: #bbb !important;
}
.react-select__multi-value {
  background: #000 !important;
}
.react-select__multi-value__label {
  color: #fff !important;
}

.react-select__multi-value__remove > svg {
  fill: #24D600 !important;
}

.react-select__input input {
  height: auto !important;
  color: #000 !important;
}
.react-select__dropdown-indicator > svg {
  fill: #d5d3d3 !important;
}
.react-select__menu {
  background: #fff !important;
}
.react-select__option {
  color: #000 !important;
}

.react-select__option:hover,.react-select__option:active,.react-select__option:focus,.react-select__option--is-focused {
  background: #ddd !important;
}

.react-select__option--is-selected {
  background: #000 !important;
  color: #fff !important;
}
