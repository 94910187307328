.container {
  width: 966px;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  padding: 30px 0;
  align-items: flex-start;
}
.container > div:first-of-type {
  padding-left: 16px;
  font-size: 26px;
}

.contactList {
  width: 100%;
  flex-grow: 1;
  max-height: 100%;
  display: flex;
  flex-direction: column;
}

.buttonWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.folderIcon {
  width: 32px;
  margin-right: 10px;
  display: block;
}

@media (min-width: 1024px){
  .container {
    min-height: 50%;
    max-height: 80%;
    padding: 30px 40px;
  }
}
