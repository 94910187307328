@media (min-width: 1024px){
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .container > * {
    width: 0 !important;
    flex-grow: 1;
    flex-shrink: 0;
  }
  .container > *:not(:first-child) {
    margin-left: 20px;
  }
}
