.header > *,.row > * {
  display: table-cell;
  vertical-align: middle;
}

.row a {
  color: #000;
}


.header {
  background: #8b8b8b;
  color: #fff;
}

.header > *,.row > * {
  padding: 6px;
  box-sizing: border-box;
  white-space: nowrap;
  border-bottom: 1px solid #dbdbdb;
}

.header > * {
  font-size: 12px;
  height: 45px;
  font-weight: 500;
  text-transform: uppercase;
}

.header > *,.row > * {
  border-right: 1px solid #dbdbdb;
}
.header > *:first-child,.row > *:first-child {
  border-left: 1px solid #dbdbdb;
}

.header > *:not(:last-child) {
  border-right: 1px solid #7b7b7b;
}

.row {
  color: #000;
  text-decoration: none;
}

.row > * {
  height: 45px;
  position: relative;
  font-size: 12px;
}

.row > *::-webkit-scrollbar {
  display: none;
}

.row > * {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.container {
  display: table;
  width: 100%;
  table-layout: auto;
}



.wrapper {
  overflow-x: auto;
  width: 100%;
}
.row,.header {
  display: table-row;
}
.addBtn {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

@media (min-width: 1024px){
  .container.fixed {
    table-layout: fixed;
  }
  .header > *,.row > * {
    padding: 6px 20px;
  }

  .header > * {
    font-size: 14px;
    height: 55px;
  }
  .container.secondary .header > * {
    height: 46px;
    font-size: 12px;
  }
  .row > * {
    height: 60px;
    font-size: inherit;
    white-space: nowrap;
    overflow-x: scroll;
  }
  .container.secondary .row > * {
    height: 55px;
  }
}

@media (min-width: 1441px){

  .row > * > * {
    font-size: 16px;
  }
}
