.container {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.paginatedButtons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}

.paginatedButtons.section {
  position: absolute;
  top: -40px;
  right: 0;
  height: 40px;
  margin: 0;
}

.paginatedButtons > * {
  width: 24px;
  cursor: pointer;
  margin: 0 5px;
}

.wingButton {
  font-size: 24px;
  text-align: center;
  margin-bottom: 5px;
}

.wingButton.disabledPageButton {
  color: #bbb;
  cursor: default;
}


.paginatedPageButton {
  font-size: 15px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.paginatedPageButton.activePageButton,.paginatedPageButton:hover {
  background: #000;
  color: #fff;
  font-weight: bold;
}

@media (min-width: 1024px) {
  .paginatedButtons > * {
    width: 28px;
  }
  .wingButton {
    font-size: 28px;
  }
  .paginatedPageButton {
    font-size: 14px;
    height: 28px;
  }
}
