.container {
  background: #000;
  color: #fff;
  border: none;
  outline: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-weight: 500;
  padding: 0 14px;
  flex-direction: column;
  height: 35px;
  min-width: 150px;
  box-sizing: border-box;
  text-decoration: none;
  text-transform: uppercase;
  flex-shrink: 0;
}

.container.center {
  margin: 0 auto;
}


.container.secondary {
  background: #24D600;
  color: #000;
}
.container.red {
  background: #ff0000;
}
.container.disabled {
  background: #C1C1C1;
  cursor: default;
}


.container .text {
  font-size: 10px;
  text-decoration: none;
  color: #fff;
}

.container.secondary .text {
  color: #000;
}

.container.form {
  min-width: 130px;
  height: 35px;
}

.container.small {
  min-width: 85px;
  height: 35px;
}

.container.form .text,.container.small .text {
  text-transform: capitalize;
}

@media (min-width: 1024px){
  .container {
    min-width: 170px;
  }
  .container .text {
    font-size: 12px;
  }
}

@media (min-width: 1441px){
  .container {
    height: 38px;
    min-width: 210px;
  }

  .container .text {
    font-size: 12px;
  }
}

.action {
  height: 60px;
  width: 60px;
  min-width: 60px;
  background: #000;
  border-radius: 50%;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0,0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.action .text img {
  display: block;
  width: 28px;
}

.action:hover {
  box-shadow: 0px 5px 5px -3px rgba(0 0 0, 0.2), 0px 8px 10px 1px rgba(0 0 0, 0.14), 0px 3px 14px 2px rgba(0 0 0, 0.12);
}
