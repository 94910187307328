.link {
  text-align: center;
  text-decoration: none;
  color: #000;
  background: #fff;
  padding: 0 8px;
  margin: 0;
  box-sizing: border-box;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 38px;
  display: flex;
  cursor: pointer;
  font-size: 16px;
  min-width: 100px;
}
