.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  width: 100%;
  padding: 40px 20px;
  box-sizing: border-box;
}

.text {
  margin-bottom: 15px;
  text-align: center;
}
