.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.content {
  width: 260px;
  max-width: 100%;
  padding: 30px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 5px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.content > form {
  display: flex;
  flex-direction: column;
}

.button {
  margin-top: 20px;
  height: 44px;
}

.button > * {
  font-size: 13px !important;
}
.header {
  text-align: center;
}

.logo {
  max-width: 100%;
  width: 150px;
}

.title {
  font-size: 26px;
  font-weight: bold;
  margin: 15px 0;
}

.link {
  text-align: center;
  color: #000;
  text-decoration: none;
  margin-top: 18px;
  font-size: 15px;
}

.link:hover {
  text-decoration: underline;
}
