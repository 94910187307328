.container {
  position: relative;
  width: 100%;
}

.hidden {
  position: absolute;
  visibility: hidden;
}

.dropzone {
  background-color: #f0f1f0;
  border: 1px dashed #24D600;
  margin: 22px 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.image {
  max-width: 100%;
  max-height: 100%;
}

.dropzone.active,.dropzone::active,.dropzone::focus {
  filter: brightness(80%);
  outline: none;
}

.requirements {
  margin-bottom: 32px;
  text-transform: none !important;
}

.error {
  top: 101% !important;
}

.placeholder,.dropzone,.fileImageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.placeholder {
  margin: 10px 0;
}

.placeholderHeader {
  font-size: 21px;
  font-weight: bold;
  margin-bottom: 10px;
}

.fileImage {
  width: 68px;
  max-width: 80%;
}

.fileName {
  font-size: 18px;
  font-weight: bold;
}

.or {
  margin-top: 6px;
}

.button {
  margin-top: 16px;
}
