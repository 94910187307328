.container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  box-sizing: border-box;
}

.text {
  font-size: 24px;
  margin: 10px 0 20px 0;
}
