.section {
  position: relative;
}

.sectionHeader {
  display: flex;
  align-items: center;
  min-height: 65px;
  font-size: 16px;
  font-weight: bold;
  flex-direction: column;
  width: 100%;
  padding: 15px 16px;
  box-sizing: border-box;
  justify-content: center;
}


.filters {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.filters > * {
  margin-top: 8px;
  min-width: 180px !important;
}

.sectionFilter {
  margin: 0 0 15px 0 !important;
}

.sectionFilter > * {
  max-width: 200px !important;
}


@media (min-width: 1024px) {
  .sectionHeader {
    justify-content: flex-start;
  }
  .sectionFilter {
    margin: 0 0 0 20px !important;
  }
  .filters,.sectionHeader {
    flex-direction: row;
    margin: 0 20px;
  }
  .filters > * {
    margin: 0;
  }
  .filters > *:not(:first-child) {
    margin-left: 12px;
  }
}
