.container {
  display: flex;
  align-items: center;
  font-size: 18px;
  padding: 15px;
  box-sizing: border-box;
  border-bottom: 1px solid #eee;
  width: 100%;
  min-height: 60px;
  text-decoration: none;
  color: #000;
}
