.container {
  width: 100%;
  position: relative;
  display: block;
  font-size: 10px;
  margin-bottom: 10px;
}

.container > *:first-child {
  display: flex;
  align-items: center;
}

.textarea {
  align-items: end;
}

.error {
  left: 0 !important;
  white-space: nowrap;
}

.inputWrapper {
  position: relative;
  flex-grow: 1;
}

.container.secondary {
  display: block;
  margin-bottom: 10px;
}

.container.secondary > *:first-child {
  height: 30px;
}

.container.secondary input, .container.secondary select {


  padding-left: 2px;
  font-size: 18px;
}

.container.secondary input {
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #24D600;
}

.small {
  max-width: 400px;
}

@media (min-width: 1024px) {
  .container {
    display: flex;
    margin-bottom: 24px;
  }
}
