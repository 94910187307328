.image {
  height: 70px;
  width: auto;
}

.links {
  display: flex;
  height: 100%;
  align-items: center;
}

.links > * {
  color: #fff;
  font-size: 14px;
  text-decoration: none;
}
.links > *:nth-child(2n+3) {
  font-size: 12px;
}

.links > *:not(:first-child) {
  margin-left: 8px;
}

.links > *:nth-child(2n+2):hover {
  text-decoration: underline;
}

.backIcon {
  transform: rotate(90deg);
  width: 22px;
}

.header {
  justify-content: space-between;
}
.header,.views {
  display: flex;
  align-items: center;
}

.view {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.view:not(:first-child) {
  margin-left: 10px;
}

.view.active {
  background: #fff;
}

.viewIcon {
  fill: #fff;
  width: 20px;
}
.view.active > .viewIcon {
  fill: #8b8b8b;
}
.list,.grid {
  flex-grow: 1;
  display: flex;
}
.list {
  flex-direction: column;
}
.grid {
  align-items: center;
  flex-wrap: wrap;
  padding: 15px 50px;
}

.card {
  width: 16.4%;
  height: 170px !important;
  margin: 0;
  cursor: pointer;
  margin: 15px 0;
}

.card:not(:nth-child(5n + 1)) {
  margin-left: 4.4%;
}
