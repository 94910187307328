.container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  color: #fff;
  background: rgba(0,0,0,0.8);
  z-index: 999999;
  margin: 0 !important;
}
.container,.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

}

.wrapper {
  height: 100%;
  width: 100%;
  flex-grow: 1;
}


.loader,
.loader:after {
  border-radius: 50%;
  width: 45px;
  height: 45px;
}
.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 8px solid rgba(255, 255, 255, 0.2);
  border-right: 8px solid rgba(255, 255, 255, 0.2);
  border-bottom: 8px solid rgba(255, 255, 255, 0.2);
  border-left: 8px solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

.wrapper .loader {
  border-left: 8px solid #000;
}

.wrapper.white .loader {
  border-left-color: #fff;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
